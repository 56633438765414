import React from 'react'
import Layout from '../../components/layout'
// import { Link, navigate } from 'gatsby'

export default function Privacy () {
  return (
    <Layout>
      <section className='legal'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1 className='bar'>Trexity Shopify App Privacy Policy</h1>
              <p>Effective date: 01/01/2019</p>

              <p><strong>Trexity Shopify App</strong> “the App” provides shipping rates and shipping services “the Service” to merchants who use Shopify to power their stores. This Privacy Policy describes how personal information is collected, used, and shared when you install or use the App in connection with your Shopify-supported store.</p>

              <h2>Personal Information the App Collects</h2>

              <p>When you install the App, we are automatically able to access certain types of information from your Shopify account:</p>

              <p>Carrier service creation (ability to register Trexity as a shipping option);</p>
              <p>Order creation events (in order to detect newly created orders with Trexity as the shipping option, and in turn creating a draft delivery);</p>
              <p>Order writing (in order to save the Trexity Route ID into your Shopify order for subsequent events);</p>
              <p>Fulfillment events (in order to finalize the Trexity delivery and begin broadcasting to nearby drivers).</p>

              <p>We collect personal information directly from the relevant individual, through your Shopify account. “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps. “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.</p>

              <h2>How Do We Use Your Personal Information?</h2>

              <p>We use the personal information we collect from you and your customers in order to provide the Service and to operate the App.</p>

              <h2>Sharing Your Personal Information</h2>

              <p>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>

              <h2>Data Retention</h2>

              <p>When you place an order through the Site, we will maintain your Order Information for our records until you ask us to delete this information.</p>

              <h2>Changes</h2>

              <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>

              <h2>Contact Us</h2>

              <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at <a href='mailto:info@trexity.com'>info@trexity.com</a>.</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
